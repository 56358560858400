import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import huron_telemed from '../img/huron_telemed.jpeg';

const TelemedicineSection = ({ openContactModal }) => {
  const openCalendarInNewTab = () => {
    window.open(
      'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1B58BNZyzibhUiinYl6C4dDByZogvvJTM2Li9LudbR7Q1MxCkYrid8h7ybIffZVGPlJ4tLXgnJ?gv=true',
      '_blank'
    );
  };

  return (
    <div className="bg-teal-600 text-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 text-center">Telemedicina Veterinaria</h2>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <img
              src={huron_telemed}
              alt="Veterinario en videollamada"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="w-full md:w-1/2 px-4">
            <h3 className="text-2xl font-semibold mb-4">Atención veterinaria desde la comodidad de tu hogar</h3>
            <p className="mb-6">
              En VetZoo, entendemos que el cuidado de tus mascotas exóticas es una prioridad. Por eso, ofrecemos consultas
              veterinarias en línea para brindarte asesoramiento experto sin necesidad de salir de casa.
            </p>
            <ul className="mb-8">
              <li className="flex items-center mb-2">
                <i className="fas fa-check-circle mr-2"></i>
                Consultas con especialistas en animales exóticos
              </li>
              <li className="flex items-center mb-2">
                <i className="fas fa-check-circle mr-2"></i>
                Disponible para hurones, erizos, chinchillas y más
              </li>
              <li className="flex items-center mb-2">
                <i className="fas fa-check-circle mr-2"></i>
                Atención rápida y personalizada
              </li>
            </ul>
            <div className="text-center">
              <button
                className="bg-white text-teal-600 font-bold py-2 px-4 rounded hover:bg-teal-100 transition duration-300"
                onClick={openCalendarInNewTab}
              >
                Agendar Consulta Online
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactModal = ({ isModalOpen, closeModal }) => {
  const [formErrors, setFormErrors] = useState({});
  const formRef = useRef();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const email = formData.get('user_email');

    if (!validateEmail(email)) {
      setFormErrors({ user_email: 'Por favor, ingrese un correo electrónico válido.' });
      return;
    }

    emailjs
      .sendForm('service_97z4b4r', 'template_wp40epn', formRef.current, '4d9zvaK_WcFsojZkK')
      .then(
        (result) => {
          console.log(result.text);
          alert('Correo enviado con éxito');
          closeModal();
        },
        (error) => {
          console.log(error.text);
          alert('Hubo un error, intenta de nuevo.');
        }
      );
  };

  return (
    isModalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-y-auto">
        <div className="bg-white rounded-lg p-8 max-w-4xl w-full">
          <h3 className="text-2xl font-bold mb-4 text-teal-600">Formulario de Contacto</h3>
          <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-black font-semibold mb-1 text-left">Nombre y Apellido *</label>
              <input
                type="text"
                name="user_name"
                required
                className="w-full px-4 py-2 border rounded focus:outline-none focus:border-teal-600 text-black"
              />
            </div>
            <div>
              <label className="block text-black font-semibold mb-1 text-left">Correo Electrónico *</label>
              <input
                type="email"
                name="user_email"
                required
                className="w-full px-4 py-2 border rounded focus:outline-none focus:border-teal-600 text-black"
              />
              {formErrors.user_email && <p className="text-red-500 text-sm">{formErrors.user_email}</p>}
            </div>
            <div>
              <label className="block text-black font-semibold mb-1 text-left">Teléfono (opcional)</label>
              <input
                type="tel"
                name="user_phone"
                className="w-full px-4 py-2 border rounded focus:outline-none focus:border-teal-600 text-black"
              />
            </div>
            <div>
              <label className="block text-black font-semibold mb-1 text-left">Describa brevemente el motivo de la consulta *</label>
              <textarea
                name="message"
                required
                className="w-full px-4 py-2 border rounded focus:outline-none focus:border-teal-600 text-black"
                rows="4"
              ></textarea>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="mt-4 px-4 py-2 bg-teal-700 text-white rounded hover:bg-teal-800 transition duration-300"
              >
                Enviar
              </button>
            </div>
          </form>
          <button
            onClick={closeModal}
            className="mt-4 px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500 transition duration-300"
          >
            Cerrar
          </button>
        </div>
      </div>
    )
  );
};

export { TelemedicineSection, ContactModal };