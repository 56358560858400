import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Hurones from "../img/Hurones.png";

const Hero = ({ openContactModal }) => {
  return (
    <div>
      <Row className="align-items-center">
        <Col md={6}>
          <img src={Hurones} alt="imagen del hero" className="img-hero" />
        </Col>
        <Col md={6}>
          <h1>Bienvenidos a VetZoo</h1>
          <p>
            En la consulta clínica veterinaria, la Dra. Déborah Bunster brinda
            atención de la más alta calidad a animales exóticos: hurones, cuyis,
            conejos, chinchillas, entre otros.
          </p>
          <p>
            Ofrecemos consulta médica, procedimientos y también se realizan
            cirugías. Consulta a través de nuestros canales de contacto.
          </p>
          <p>Estamos ubicados en Las Condes, Santiago de Chile.</p>
          <Button variant="primary" className="bg-color m-2 border-0">
            <a href="tel:+56976128706" className="text-white" style={{ textDecoration: 'none' }}>
              Teléfono
            </a>
          </Button>
          <Button variant="primary" className="bg-color m-2 border-0">
            <a href="mailto:consultavetzoo@gmail.com" className="text-white" style={{ textDecoration: 'none' }}>
              E-Mail
            </a>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Hero;
